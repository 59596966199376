export function createStandortDescription(standort, page) {
  switch (page) {
    case 'Homepage':
      return `Selbstverteidigungskurse und Kampfsporttraining für Kinder, Jugendliche und Erwachsene. TWIN Taekwondo in ${standort}. Jetzt mehr erfahren.`
      break
    case 'Unterrichtsplan':
      return `Hier finden Sie eine Übersicht der aktuellen Unterrichtsstunden am TWIN Taekwondo Standort ${standort}. Melden Sie sich bei Interesse gerne vorab an.`
      break
    case 'Galerie':
      return `Bildergalerie vom TWIN Taekwondo Standort ${standort}. Hier finden Sie Bilder und Videos von Veranstaltungen und aus dem Taekwondo Unterricht. Hier klicken.`
      break
    case 'Termine':
    case 'Veranstaltungen':
      return `Hier finden Sie einen Überblick der aktuellen Veranstaltungen am TWIN Taekwondo Standort ${standort}. Reinklicken und mehr erfahren.`
      break
    case 'Dantraeger':
      return `Unsere Danträger am Standort ${standort}. Der Dan Grad bezeichnet im Taekwondo den Fortgeschrittenen- bzw. Meistergrad. <<< Zu unseren Danträgern >>>`
      break
    default:
      return 'TWIN-Taekwondo'
      break
  }
}

export function createStandortTitle(standort, page) {
  switch (page) {
    case 'Homepage':
      return `TWIN Taekwondo in ${standort} - Die neue Art der alten Kunst`
      break
    case 'Unterrichtsplan':
      return `Aktueller Unterrichtsplan TWIN Taekwondo ${standort}`
      break
    case 'Galerie':
      return `Bildergalerie TWIN Taekwondo ${standort}`
      break
    case 'Termine':
    case 'Veranstaltungen':
      return `Veranstaltungen des TWIN Taekwondo Standortes ${standort}`
      break
    case 'Dantraeger':
      return `Danträger am TWIN Taekwondo Standort ${standort}`
      break
    default:
      return 'TWIN-Taekwondo'
      break
  }
}
