export default (anchor) => {
  setTimeout(() => {
    const el = document.getElementById(anchor)

    if (!el) return console.warn('!el')
    // console.log("el", el);

    el.scrollIntoView()
  }, 150)
}
