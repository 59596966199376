import React from 'react'
import  Box  from 'components/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

function FullScreenLoader () {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  )
}

export default FullScreenLoader
